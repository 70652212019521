
import { Component, Vue } from "vue-property-decorator";

// Config
import { RouteNames } from "@/config/router.config";
import { track, TrackingEventType } from "@/config/tracking.config";

// Components
import PageHeader from "@/components/page-header.component.vue";

@Component({
  components: {
    PageHeader
  }
})
export default class SizeGuide extends Vue {

  protected created() {
    track(TrackingEventType.ViewContent, {
      content_category: RouteNames.SizeGuide
    });
  }

}
